<template>
  <div class="app-body">
    <div class="a-flex-rsbc a-ptb-15">
      <span class="a-fs-16 a-fw-b">客户管理</span>
      <div>
        <le-export-btn v-if="false" :page-param="pageParam"></le-export-btn>
        <el-button
          icon="el-icon-plus"
          type="primary"
          class="s-btn-add a-ml-24 myblue"
          @click="handlerAddCustomer"
        >
          客户
        </el-button>
      </div>
    </div>

    <el-card class="el-main">
      <le-search-form @reset="handlerRest" @search="handlerSearch">
        <le-input-icon-search v-model="pageParam.params.searchKey" label="商户名称" />
        <le-select-local-search
          v-model="pageParam.params.status"
          label="商户状态"
          :options="customerStatusOptions"
          class="a-ml-48"
        />
      </le-search-form>

      <le-pagview :page-param="pageParam" @setData="setTableData">
        <el-table :data="tableData" :highlight-current-row="true" style="width: 100%">
          <el-table-column prop="brand" label="商户简称"></el-table-column>
          <el-table-column prop="name" label="商户全称"></el-table-column>
          <el-table-column label="主体类型">
            <template slot-scope="{ row }">
              {{ type[row.newType] }}
            </template>
          </el-table-column>
          <el-table-column prop="orderId" label="所在地区">
            <template slot-scope="{ row }">
              {{ row.province }}{{ row.city }}{{ row.district }}
            </template>
          </el-table-column>
          <el-table-column prop="regTimeText" label="申请日期"></el-table-column>
          <el-table-column prop="regStateText" label="审核状态">
            <template slot-scope="{ row }">
              <el-tag v-if="row.regState == 3" type="danger">{{ row.regStateText }}</el-tag>
              <el-tag v-else>{{ row.regStateText }}</el-tag>
            </template>
          </el-table-column>

          <el-table-column fixed="right" label="操作" class="a-flex-rcc">
            <template slot-scope="{ row }">
              <el-tooltip class="item" effect="dark" content="详情" placement="top">
                <span class="a-cursor-p" @click="handlerGoDetail(row.companyId)">
                  <img src="../../assets/icon/option-detail.png" class="a-wh-16 a-cursor-p" />
                </span>
              </el-tooltip>
              <el-tooltip
                v-if="
                  pageParam.params.status == 0 &&
                  company.id == 2 &&
                  row.regState != 3 &&
                  row.regState != 4
                "
                class="item a-ml-12"
                effect="dark"
                content="审核通过"
                placement="top"
              >
                <span @click="handlerApproved(row.companyId)">
                  <img src="../../assets/icon/option-agree.png" class="a-wh-16 a-cursor-p" />
                </span>
              </el-tooltip>
              <el-tooltip
                v-if="
                  pageParam.params.status == 0 &&
                  company.id == 2 &&
                  row.regState != 3 &&
                  row.regState != 4
                "
                class="item a-ml-12"
                effect="dark"
                content="审核拒绝"
                placement="top"
              >
                <span @click="handlerApproved(row.companyId, 'authResult')">
                  <img src="../../assets/icon/option-refuse.png" class="a-wh-16 a-cursor-p" />
                </span>
              </el-tooltip>
            </template>
          </el-table-column>
        </el-table>
      </le-pagview>
    </el-card>
    <le-examine-dialog
      ref="examine"
      :dialog-obj.sync="examineDialog"
      @objection="objection"
    ></le-examine-dialog>
  </div>
</template>

<script>
import { mapState } from "vuex";

import util from "../../utils/util.js";
import LeExamineDialog from "../components/poppup/le-examine-dialog.vue";
export default {
  name: "CustomerList",

  components: { LeExamineDialog },
  data() {
    return {
      util: util,
      customerStatusOptions: [
        {
          value: 0,
          label: "入驻中",
        },
        {
          value: 1,
          label: "已入驻",
        },
      ],

      tableData: [],
      pageParam: {
        url: this.$Config.apiUrl.getCompanyManagerList,
        method: "post",
        params: {
          searchKey: "",
          status: 0,
        },
        freshCtrl: 1,
      },
      examineDialog: {
        title: "审核拒绝",
        dialogFormVisible: false,
        objection: "",
        placeholder: "请输入拒绝原因...",
        cancle: "取消",
        refuse: "确定",
      },
      type: ["自然人", "企业", "个体工商户", "个体工商户", "政府及事业单位", "其他组织"],
    };
  },
  computed: {
    ...mapState({
      company: (state) => state.company.company,
    }),
  },
  watch: {
    "pageParam.params.status": {
      handler() {
        this.handlerSearch();
      },
      deep: true,
    },
  },
  mounted() {},
  methods: {
    //获取车辆列表
    setTableData(data) {
      this.tableData = data;
    },

    handlerRest() {
      this.pageParam.params = {
        searchKey: "",
        status: 0,
      };
    },

    handlerSearch() {
      this.pageParam.freshCtrl++;
    },
    //跳转详情
    handlerGoDetail(id) {
      this.$router.push({
        path: "/customer/customer-detail",
        query: {
          companyId: id,
        },
      });
    },
    handlerAddCustomer() {
      this.$router.push({
        path: "/customer/customer-add",
      });
    },
    //审核按钮
    handlerApproved(id, type) {
      this.currentCompanyId = id;
      if (type == "authResult") {
        this.examineDialog.dialogFormVisible = true;
      } else {
        this.approved(id);
      }
    },
    //审核请求
    approved(id, val) {
      this.$Axios
        ._post({
          url: this.$Config.apiUrl.authCompany,
          params: {
            authResult: val ? false : true,
            authMsg: val ? val : "",
            companyId: id || this.currentCompanyId,
          },
        })
        .then((res) => {
          if (res.result.code == 0) {
            this.$notify({
              title: "审核成功",
              type: "success",
            });
            this.handlerSearch();
          } else {
            this.$message.error(res.result.msg);
          }
        })
        .catch((error) => {
          this.$message.error(error);
        });
    },
    //审核拒绝
    objection(val) {
      this.approved(this.currentCompanyId, val);
    },
  },
};
</script>

<style lang="scss" scoped>
/deep/ .s-search-label {
  width: 72px;
  justify-content: flex-start;
}

/deep/ .s-search-label + div {
  margin-left: 0;
}
</style>
