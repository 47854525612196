<template>
  <div>
    <el-dialog
      :title="dialogObj.title"
      destroy-on-close
      :visible.sync="dialogObj.dialogFormVisible"
      width="30%"
    >
      <el-form>
        <el-form-item>
          <el-input
            v-model="dialogObj.objection"
            type="textarea"
            clearable
            maxlength="150"
            show-word-limit
            :placeholder="dialogObj.placeholder"
            :autosize="{ minRows: 6, maxRows: 10 }"
          ></el-input>
        </el-form-item>
      </el-form>
      <div slot="footer" class="dialog-footer">
        <el-button @click="dialogObj.dialogFormVisible = false">{{ dialogObj.cancle }}</el-button>

        <el-button v-if="dialogObj.refuse" type="primary" @click.native="objection('refuse')">
          {{ dialogObj.refuse }}
        </el-button>
        <el-button v-if="dialogObj.sure" type="primary" @click.native="objection">
          {{ dialogObj.sure }}
        </el-button>
      </div>
    </el-dialog>
  </div>
</template>

<script>
export default {
  props: {
    dialogObj: {
      type: Object,
      require: true,
    },
  },
  data() {
    return {};
  },
  methods: {
    objection(type) {
      if (type == "refuse" && !this.dialogObj.objection) {
        this.$message.error("请输入拒绝理由！");
        return false;
      }
      this.$emit("objection", this.dialogObj.objection, type);
      if (type == "refuse") {
        this.$emit("refuse", this.dialogObj.objection);
      } else {
        this.$emit("submit", this.dialogObj.objection);
      }

      this.$emit("visible:update", false);
      this.dialogObj.dialogFormVisible = false;
      this.dialogObj.objection = "";
    },
  },
};
</script>

<style lang="scss" scoped></style>
